const allStatus = {
    // STATUS OF ORDER
    saved: 1,
    sent: 2,
    underway: 3,
    withCaptain: 4,
    withCompany: 5,
    returned: 6,
    given: 7,
    wallet: 8,
    deleted: 9,
    resend: 10,
    missing: 11,
    expiredReturn: 12,
    delayed: 13,
    uncompleted: 99
}

export default allStatus